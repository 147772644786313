<template>
  <v-card class="mx-auto pa-2 text-center" max-width="300" id="icon-container" v-if="items.length > 0">
    <v-list-subheader class="mb-2">Contattami</v-list-subheader>

    <v-row justify="center">
      <v-col v-for="(item, i) in items" :key="i">
        <v-list-item :value="item" rounded="xl" :href="item.link" :target="item.target">
          <template v-slot:default>
            <v-icon :icon="item.icon" class="icon"></v-icon>
          </template>
        </v-list-item>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'NavBar',
  data: () => ({
    items: [
      { icon: 'mdi-email', link: 'mailto:info@redlightrossiluca.com', target: '_blank' },
      { icon: 'mdi-linkedin', link: 'https://www.linkedin.com/', target: '_blank' },
      { icon: 'mdi-instagram', link: 'https://www.instagram.com/', target: '_blank' }
    ],
  }),
};
</script>

<style scoped>
#icon-container {
  background-color: #97B180; /* Sfondo bianco */
  padding: 10px; /* Aggiungi spazio interno per far risaltare l'ombra */
  border-radius: 10px; /* Arrotonda i bordi */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombra con sfumatura */
}

/* Stili per disporre le icone orizzontalmente */
#icon-container .v-row {
  display: flex;
  justify-content: center;
}

/* Stili per le icone */
.icon {
  margin: -2px; /* Aggiungi margine intorno alle icone */
}

/* Transizione per ingrandire le icone al passaggio */
.icon:hover {
  transform: scale(1.2);
}


</style>
