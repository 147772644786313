<template>
    <v-container fluid>
      <v-row justify="center" class="mx-auto">
        <v-col cols="12" sm="8" md="6" lg="4" class="text-center">
          <v-card class="logo-container" justify="space-around">
            <v-img
              class="immagine"
              src="../assets/logoPrincipale.png"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'LogoAzzienda',
  };
  </script>
  
  <style scoped>
  /* Stili per ingrandire il LogoAzzienda */
  .logo-container {
    margin: 0 auto; /* Centra l'elemento orizzontalmente */
    transition: transform 0.3s ease; /* Aggiungi una transizione per l'ingrandimento */
  }
  
  /* Aggiungi una regola quando passi sopra l'immagine */
  .logo-container:hover {
    transform: scale(1.05); /* Ingrandisci del 5% */
  }
  
  .immagine {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 1000px; /* Altezza massima dell'immagine */
  }
  </style>
  