<template>
  <v-container>
    <!-- Aggiungi un titolo centrato con ombreggiatura -->
    <v-row align="center" justify="center" class="title-row">
      <h4 class="text-h4">Le mie abilitazioni</h4>
    </v-row>

    <v-row align="center" justify="center" class="competenze-row">
      <v-col v-for="(competenza, index) in competenze" :key="index" cols="12" sm="6" md="4" lg="3">
        <v-card class="card-custom" elevation="10">
          <v-img :src="competenza.immagine" class="immagine" contain></v-img>
          <v-card-text class="card-content">
            <div class="text-overline mb-1 font-weight-bold">{{ competenza.nome }}</div>
            <div class="text-caption">{{ competenza.descrizione }}</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LogoAzzienda',
  setup() {
    const competenze = [
      { nome: 'Installazione impianti elettrici e domotica', descrizione: 'Installazione di impianti elettrici residenziali, industriali e realizzazione di cabine di media tensione.', immagine: require('@/assets/domotica.png') },
      { nome: 'Impianti trasmissione dati', descrizione: 'Manutenzione programmata e interventi di emergenza su impianti elettrici.', immagine: require('@/assets/rete.png') },
      { nome: 'Installazione bordo macchine', descrizione: 'Realizzazione e assistenza a bordo macchine', immagine: require('@/assets/plc.png') }
    ];

    return { competenze };
  }
}
</script>

<style scoped>
.title-row {
  margin-top: 30px; /* Aggiungi margine superiore */
}

.competenze-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.card-custom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s, opacity 0.5s;
  cursor: default;
  width: 100%;
  min-height: 400px; /* Imposta un'altezza minima comune per tutte le card */
}

.card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.immagine {
  border-radius: 15px 15px 0 0;
}

.text-h4 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.font-weight-bold {
  font-weight: bold;
}
</style>
