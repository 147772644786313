<template>
  <div class="loading-animation" v-if="isLoading">
    <div class="loading-text">
      <span
        class="red-text"
        v-for="(letter, index) in letters"
        :key="index"
        :style="{ animationDelay: `${index * 0.1}s` }"
      >{{ letter }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingAnimation",
  data() {
    return {
      isLoading: true,
      letters: ["R", "E", "D", "L", "I", "G", "H", "T"],
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000); // Nasconde l'animazione dopo 2 secondi
  },
};
</script>

<style scoped>
.loading-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #070000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-text {
  font-size: 15vw; /* Dimensione del font responsiva */
  text-align: center;
}

.red-text {
  color: transparent;
  animation: colorize 2s infinite linear;
}

@keyframes colorize {
  0% {
    color: transparent;
  }
  100% {
    color: #ff0000;
  }
}

/* Media query per schermi più piccoli */
@media (max-width: 600px) {
  .loading-text {
    font-size: 20vw; /* Più grande su schermi piccoli */
  }
}

/* Media query per schermi grandi */
@media (min-width: 1200px) {
  .loading-text {
    font-size: 5vw; /* Più piccolo su schermi grandi */
  }
}
</style>
