<template>
  <v-container>
    <h1 class="text-center">Progetti</h1>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto my-4" max-width="100%">
          <v-img
            class="responsive-img"
            src="@/assets/progetti.jpg"
            cover
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProgettiAzienda",
};
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
}

.responsive-img {
  width: 100%; /* Assicura che l'immagine si adatti alla larghezza del contenitore */
  height: auto; /* Mantiene le proporzioni dell'immagine */
  max-height: 600px; /* Limita l'altezza massima per schermi più grandi */
}
</style>
