<template>
    <v-card class="mx-auto custom-card" max-width="600">
      <v-img height="640" src='../assets/fotoProfilo.jpeg' cover></v-img>
  
      <v-card-title class="display-3">
        Luca Rossi
      </v-card-title>
  
      <v-card-subtitle class="headline">
        <h3>
          Esperienza trentennale
        </h3>
      </v-card-subtitle>
      <v-expand-transition>
        <div v-show="showContent" class="content">
          <v-divider></v-divider>
          <v-card-text class="body-1">
            Sono Rossi Luca ho 30 anni di esperienza nel settore elettrico come impiantista. Negli anni ho acquisito sul campo capcità tecniche in merito agli impianti tecnologici civili ed industriali 
            e, proprio grazie alla mia vasta copetenza offro un servizio qualitativo nel settore
          </v-card-text>
  
          <!-- Sezione competenze -->
          <v-divider></v-divider>
          <v-card-text class="text-center body-1" style="font-size: 1.2rem;">
            <h3>Competenze:</h3>
            <ul>
              <li><v-icon>mdi-electric-switch</v-icon> Impianti elettrici Civili Industriali BT/MT</li>
              <li><v-icon>mdi-robot-industrial</v-icon> Automazione industriale</li>
              <li><v-icon>mdi-solar-power</v-icon> Energia rinnovabili</li>
              <li><v-icon>mdi-wrench</v-icon> Sistemi di controllo PLC</li>
              <li><v-icon>mdi-transmission-tower</v-icon> Reti dati </li>
              <li><v-icon>mdi-home-automation</v-icon> Domotica e smart home</li>
            </ul>
          </v-card-text>
        </div>
      </v-expand-transition>
      
    </v-card>
  </template>
  
  <script>
  export default {
    name: 'MainCard',
    data() {
      return {
        showContent: false
      }
    },
    methods: {
      handleScroll() {
        if (window.scrollY > 50) {
          this.showContent = true;
        } else {
          this.showContent = false;
        }
      },
      openPdf() {
        // Implementa qui la logica per visualizzare e scaricare il PDF
          // URL del PDF da visualizzare e scaricare
        const pdfUrl = './assets/esempio.pdf';

        // Apri il PDF in una nuova scheda
        window.open(pdfUrl, '_blank');

        // Per scaricare il PDF, puoi creare un link temporaneo e avviare il download
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'curriculimLucaRossi.pdf'; // Il nome con cui verrà scaricato il file
        link.click();
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
      window.removeEventListener('scroll', this.handleScroll);
    }
  };
  </script>
  
  <style scoped>
  .custom-card {
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .content {
    padding: 10px;
  }
  
  .content h3 {
    margin-bottom: 5px;
  }
  
  .content ul {
    list-style: none;
    padding-left: 0;
  }
  
  .content ul li {
    margin-bottom: 8px;
  }
  
  @media (max-width: 600px) {
    .custom-card {
      max-width: 100%;
    }
  }
  </style>
  